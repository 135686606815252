.r180 {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'r180' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.r180.Accessibility:before {
  content: "\e912";
}
.r180.anchor-video:before {
  content: "\e900";
}
.r180.arrow-down:before {
  content: "\e901";
}
.r180.arrow-left:before {
  content: "\e902";
}
.r180.arrow-right:before {
  content: "\e903";
}
.r180.arrow-up:before {
  content: "\e904";
}
.r180.back:before {
  content: "\e905";
}
.r180.back-from-the-brink:before {
  content: "\e906";
}
.r180.brains:before {
  content: "\e907";
}
.r180.breaking-the-mold:before {
  content: "\e908";
}
.r180.check:before {
  content: "\e909";
}
.r180.check-answer:before {
  content: "\e963";
}
.r180.close:before {
  content: "\e90a";
}
.r180.close-alt:before {
  content: "\e90b";
}
.r180.closed-captions:before {
  content: "\e90c";
}
.r180.collapse:before {
  content: "\e90d";
}
.r180.consejo:before {
  content: "\e90e";
}
.r180.delete:before {
  content: "\e957";
}
.r180.earth:before {
  content: "\e90f";
}
.r180.edit:before {
  content: "\e910";
}
.r180.espanol:before {
  content: "\e911";
}
.r180.examples-non-examples:before {
  content: "\e964";
}
.r180.exit-full-screen:before {
  content: "\e913";
}
.r180.expands:before {
  content: "\e914";
}
.r180.explore:before {
  content: "\e915";
}
.r180.eye-of-the-storm:before {
  content: "\e916";
}
.r180.f-forward:before {
  content: "\e917";
}
.r180.faster:before {
  content: "\e960";
}
.r180.fluency:before {
  content: "\e918";
}
.r180.freedom:before {
  content: "\e919";
}
.r180.full-screen:before {
  content: "\e91a";
}
.r180.get-tech:before {
  content: "\e91b";
}
.r180.go-on:before {
  content: "\e974";
}
.r180.grassroots:before {
  content: "\e91c";
}
.r180.help:before {
  content: "\e91d";
}
.r180.hidden-worlds:before {
  content: "\e91e";
}
.r180.highlight:before {
  content: "\e91f";
}
.r180.info:before {
  content: "\e920";
}
.r180.instructions:before {
  content: "\e961";
}
.r180.language:before {
  content: "\e921";
}
.r180.listen:before {
  content: "\e965";
}
.r180.lock:before {
  content: "\e922";
}
.r180.mic:before {
  content: "\e923";
}
.r180.mixup:before {
  content: "\e924";
}
.r180.more:before {
  content: "\e925";
}
.r180.mosaic-nation:before {
  content: "\e926";
}
.r180.moving:before {
  content: "\e927";
}
.r180.organizer:before {
  content: "\e928";
}
.r180.parts:before {
  content: "\e929";
}
.r180.passage:before {
  content: "\e92a";
}
.r180.pause:before {
  content: "\e92b";
}
.r180.play:before {
  content: "\e92c";
}
.r180.play-on:before {
  content: "\e92d";
}
.r180.pov:before {
  content: "\e92e";
}
.r180.power-words:before {
  content: "\e92f";
}
.r180.preview:before {
  content: "\e930";
}
.r180.print:before {
  content: "\e931";
}
.r180.publish:before {
  content: "\e966";
}
.r180.rating-1:before {
  content: "\e932";
}
.r180.rating-2:before {
  content: "\e933";
}
.r180.rating-3:before {
  content: "\e934";
}
.r180.rating-4:before {
  content: "\e935";
}
.r180.reach-out:before {
  content: "\e936";
}
.r180.read:before {
  content: "\e967";
}
.r180.read-aloud:before {
  content: "\e937";
}
.r180.reading:before {
  content: "\e938";
}
.r180.reset:before {
  content: "\e939";
}
.r180.rewind:before {
  content: "\e93a";
}
.r180.roots:before {
  content: "\e93b";
}
.r180.save:before {
  content: "\e968";
}
.r180.scratch-pad:before {
  content: "\e93c";
}
.r180.sentence:before {
  content: "\e93d";
}
.r180.share:before {
  content: "\e93e";
}
.r180.showtime:before {
  content: "\e93f";
}
.r180.skip-to-end:before {
  content: "\e958";
}
.r180.skip-to-start:before {
  content: "\e95e";
}
.r180.slow:before {
  content: "\e940";
}
.r180.slower:before {
  content: "\e962";
}
.r180.speaker-center:before {
  content: "\e941";
}
.r180.spelling:before {
  content: "\e942";
}
.r180.split:before {
  content: "\e969";
}
.r180.spot:before {
  content: "\e96a";
}
.r180.stand-up:before {
  content: "\e943";
}
.r180.star:before {
  content: "\e944";
}
.r180.stop:before {
  content: "\e945";
}
.r180.strategy:before {
  content: "\e946";
}
.r180.success:before {
  content: "\e947";
}
.r180.swap:before {
  content: "\e948";
}
.r180.synonym-antonyms:before {
  content: "\e949";
}
.r180.thumbs-down:before {
  content: "\e95c";
}
.r180.thumbs-up:before {
  content: "\e95d";
}
.r180.tip:before {
  content: "\e94a";
}
.r180.tomorrowland:before {
  content: "\e94b";
}
.r180.transformers:before {
  content: "\e94c";
}
.r180.truegrit:before {
  content: "\e94d";
}
.r180.uncivil:before {
  content: "\e94e";
}
.r180.view-list:before {
  content: "\e94f";
}
.r180.voyagers:before {
  content: "\e950";
}
.r180.warning:before {
  content: "\e95f";
}
.r180.war-zone:before {
  content: "\e951";
}
.r180.wild-things:before {
  content: "\e952";
}
.r180.word:before {
  content: "\e953";
}
.r180.word-empty:before {
  content: "\e954";
}
.r180.word-families:before {
  content: "\e956";
}
.r180.wordincontext:before {
  content: "\e955";
}
.r180.writing:before {
  content: "\e959";
}
.r180.x:before {
  content: "\e95a";
}
.r180.zone-menu:before {
  content: "\e95b";
}  
  