@import './fonts/r180/r180-font.css';

/**
   Modals
 **/
.r180-modal .modal-window {
    top: 400px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('fonts/opensans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-Bold';
    src: url('fonts/opensans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('fonts/opensans/OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-Italic';
    src: url('fonts/opensans/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('fonts/opensans/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-Light';
    src: url('fonts/opensans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather';
    src: url('fonts/merriweather/Merriweather-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather-Italic';
    src: url('fonts/merriweather/Merriweather-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather-Bold';
    src: url('fonts/merriweather/Merriweather-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather-Bold-Italic';
    src: url('fonts/merriweather/Merriweather-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'r180';
    src:
      url('fonts/r180/r180.ttf?gc2hpf') format('truetype'),
      url('fonts/r180/r180.woff?gc2hpf') format('woff'),
      url('fonts/r180/r180.svg?gc2hpf#r180') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  

/**
  These are util classes from Bootstrap that I've found helpful over the years
**/
.float-left {
    float: left !important;
}
.float-right {
    float: right !important;
}
.float-none {
    float: none !important;
}
.m-0 {
    margin: 0 !important;
}
.mt-0,.my-0 {
    margin-top: 0 !important;
}
.mr-0,.mx-0 {
    margin-right: 0 !important;
}
.mb-0,.my-0 {
    margin-bottom: 0 !important;
}
.ml-0,.mx-0 {
    margin-left: 0 !important;
}
.m-1 {
    margin: 0.5rem !important;
}
.mt-1,.my-1 {
    margin-top: 0.5rem !important;
}
.mr-1,.mx-1 {
    margin-right: 0.5rem !important;
}
.mb-1,.my-1 {
    margin-bottom: 0.5rem !important;
}
.ml-1,.mx-1 {
    margin-left: 0.5rem !important;
}
.m-2 {
    margin: 1.0rem !important;
}
.mt-2,.my-2 {
    margin-top: 1.0rem !important;
}
.mr-2,.mx-2 {
    margin-right: 1.0rem !important;
}
.mb-2,.my-2 {
    margin-bottom: 1.0rem !important;
}
.ml-2,.mx-2 {
    margin-left: 1.0rem !important;
}
.m-3 {
    margin: 1.5rem !important;
}
.mt-3,.my-3 {
    margin-top: 1.5rem !important;
}
.mr-3,.mx-3 {
    margin-right: 1.5rem !important;
}
.mb-3,.my-3 {
    margin-bottom: 1.5rem !important;
}
.ml-3,.mx-3 {
    margin-left: 1.5rem !important;
}
.m-4 {
    margin: 2rem !important;
}
.mt-4,.my-4 {
    margin-top: 2rem !important;
}
.mr-4,.mx-4 {
    margin-right: 2rem !important;
}
.mb-4,.my-4 {
    margin-bottom: 2rem !important;
}
.ml-4,.mx-4 {
    margin-left: 2rem !important;
}
.m-5 {
    margin: 2.5rem !important;
}
.mt-5,.my-5 {
    margin-top: 2.5rem !important;
}
.mr-5,.mx-5 {
    margin-right: 2.5rem !important;
}
.mb-5,.my-5 {
    margin-bottom: 2.5rem !important;
}
.ml-5,.mx-5 {
    margin-left: 2.5rem !important;
}
.m-6 {
    margin: 3rem !important;
}
.mt-6,.my-6 {
    margin-top: 3rem !important;
}
.mr-6,.mx-6 {
    margin-right: 3rem !important;
}
.mb-6,.my-6 {
    margin-bottom: 3rem !important;
}
.ml-6,.mx-6 {
    margin-left: 3rem !important;
}
.p-0 {
    padding: 0 !important;
}
.pt-0,.py-0 {
    padding-top: 0 !important;
}
.pr-0,.px-0 {
    padding-right: 0 !important;
}
.pb-0,.py-0 {
    padding-bottom: 0 !important;
}
.pl-0,.px-0 {
    padding-left: 0 !important;
}
.p-1 {
    padding: 0.5rem !important;
}
.pt-1,.py-1 {
    padding-top: 0.5rem !important;
}
.pr-1,.px-1 {
    padding-right: 0.5rem !important;
}
.pb-1,.py-1 {
    padding-bottom: 0.5rem !important;
}
.pl-1,.px-1 {
    padding-left: 0.5rem !important;
}
.p-2 {
    padding: 1.0rem !important;
}
.pt-2,.py-2 {
    padding-top: 1.0rem !important;
}
.pr-2,.px-2 {
    padding-right: 1.0rem !important;
}
.pb-2,.py-2 {
    padding-bottom: 1.0rem !important;
}
.pl-2,.px-2 {
    padding-left: 1.0rem !important;
}
.p-3 {
    padding: 1.5rem !important;
}
.pt-3,.py-3 {
    padding-top: 1.5rem !important;
}
.pr-3,.px-3 {
    padding-right: 1.5rem !important;
}
.pb-3,.py-3 {
    padding-bottom: 1.5rem !important;
}
.pl-3,.px-3 {
    padding-left: 1.5rem !important;
}
.p-4 {
    padding: 2rem !important;
}
.pt-4,.py-4 {
    padding-top: 2rem !important;
}
.pr-4,.px-4 {
    padding-right: 2rem !important;
}
.pb-4,.py-4 {
    padding-bottom: 2rem !important;
}
.pl-4,.px-4 {
    padding-left: 2rem !important;
}
.p-5 {
    padding: 2.5rem !important;
}
.pt-5,.py-5 {
    padding-top: 2.5rem !important;
}
.pr-5,.px-5 {
    padding-right: 2.5rem !important;
}
.pb-5,.py-5 {
    padding-bottom: 2.5rem !important;
}
.pl-5,.px-5 {
    padding-left: 2.5rem !important;
}
.p-5 {
    padding: 3rem !important;
}
.pt-5,.py-5 {
    padding-top: 3rem !important;
}
.pr-5,.px-5 {
    padding-right: 3rem !important;
}
.pb-5,.py-5 {
    padding-bottom: 3rem !important;
}
.pl-5,.px-5 {
    padding-left: 3rem !important;
}
.m-auto {
    margin: auto !important;
}
.mt-auto,.my-auto {
    margin-top: auto !important;
}
.mr-auto,.mx-auto {
    margin-right: auto !important;
}
.mb-auto,.my-auto {
    margin-bottom: auto !important;
}
.ml-auto,.mx-auto {
    margin-left: auto !important;
}

